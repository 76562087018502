import axios from "axios";

// actions
export const INIT = "reps/INIT";
export const ERROR = "reps/ERROR";
export const SUCCESS = "reps/SUCCESS";

// reducer
export const initialState = {
  reps: [],
  hasError: false,
  error: "",
  isLoading: false,
};

export default function reps(state = initialState, action = {}) {
  switch (action.type) {
    case INIT:
      return { ...initialState, isLoading: true };
    case ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error:
          action.payload ||
          "There was an error retrieving reps details, please try again later.",
      };
    case SUCCESS:
      return {
        ...initialState,
        reps: action.payload || [],
      };
    default:
      return state;
  }
}

// action creators
export function repsFetchInit() {
  return { type: INIT };
}

export function repsFetchError(err) {
  return { type: ERROR, payload: err };
}

export function repsFetchSuccess(reps) {
  return { type: SUCCESS, payload: reps };
}

// thunks
export function fetchStreetReps() {
  return async dispatch => {
    dispatch(repsFetchInit());
    try {
      const response = await axios.get("/api/users?role=streetRep");
      dispatch(repsFetchSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.msg;
      dispatch(repsFetchError(errorMessage));
    }
  };
}
