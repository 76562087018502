import axios from "axios";
import { message } from "antd";
import { ownRentalRecordsClear } from "./ownRentalRecords";

message.config({
  top: 100,
});

// actions
const SET_USER = "user/SET_USER";
const REQUESTED_SUPER = "user/REQUESTED_SUPER";
const UPDATE_USER = "user/UPDATE_USER";

// reducer
export default function user(state = {}, action = {}) {
  switch (action.type) {
    case SET_USER:
      if (action.payload) {
        return { ...action.payload };
      }
      return state;
    case REQUESTED_SUPER:
      return { ...state, hasRequestedSuper: true };
    case UPDATE_USER:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}

// action creators
export function setUser(user) {
  return { type: SET_USER, payload: user };
}

export function setRequestedSuper() {
  return { type: REQUESTED_SUPER };
}

export function updateUserState(fields) {
  return { type: UPDATE_USER, payload: fields };
}

// thunks

export function fetchUserAndUpdate() {
  return async (dispatch, getState) => {
    const { user } = getState();
    try {
      const updatedUser = await axios.get(`/api/users/${user._id}`);
      setUser(updatedUser);
    } catch (err) {
      console.error(err);
    }
  };
}

export function destroyUser(callback) {
  return async (dispatch, getState) => {
    const { user } = getState();
    try {
      await axios.delete(`/api/users/${user._id}`);
      dispatch(setUser({ isAuthenticated: false }));
      dispatch(ownRentalRecordsClear());
      message.success(
        "All your personal data and rental data has been removed permanently from our servers. Thank you for using Rent Check.",
        5,
        callback,
      );
    } catch (err) {
      message.error(
        "There was an error deleting your data. Please try again later.",
        5,
      );
    }
  };
}
