import axios from "axios";
import { message } from "antd";

message.config({
  top: 100,
});

// actions
export const INIT = "ownRentalRecords/INIT";
export const ERROR = "ownRentalRecords/ERROR";
export const SUCCESS = "ownRentalRecords/SUCCESS";
export const CLEAR = "ownRentalRecords/CLEAR";

// reducer
export const initialState = {
  records: [],
  hasError: false,
  error: "",
  isLoading: false,
};

export default function ownRentalRecords(state = initialState, action = {}) {
  switch (action.type) {
    case INIT:
      return { ...initialState, isLoading: true };
    case ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        error:
          action.payload ||
          "There was an error retrieving ownRentalRecords details, please try again later.",
      };
    case SUCCESS:
      return {
        ...initialState,
        records: action.payload || [],
      };
    case CLEAR:
      return { ...initialState };
    default:
      return state;
  }
}

// action creators
export function ownRentalRecordsFetchInit() {
  return { type: INIT };
}

export function ownRentalRecordsFetchError(err) {
  return { type: ERROR, payload: err };
}

export function ownRentalRecordsFetchSuccess(ownRentalRecords) {
  return { type: SUCCESS, payload: ownRentalRecords };
}

export function ownRentalRecordsClear() {
  return { type: CLEAR };
}

// thunks
export function fetchOwnRentalRecords() {
  return async (dispatch, getState) => {
    const { user } = getState();
    dispatch(ownRentalRecordsFetchInit());
    try {
      const response = await axios.get(`/api/users/${user._id}/rentalRecords`);
      dispatch(ownRentalRecordsFetchSuccess(response.data));
    } catch (error) {
      const errorMessage =
        error.response && error.response.data && error.response.data.msg;
      console.error(error);
      dispatch(ownRentalRecordsFetchError(errorMessage));
    }
  };
}

export function deleteOwnRentalRecord(id, callback) {
  return async (dispatch, getState) => {
    const { user } = getState();
    try {
      await axios.delete(`/api/users/${user._id}/rentalRecords/${id}`);
      dispatch(fetchOwnRentalRecords());
      message.success(
        "Your rental data record has been permanently deleted",
        5,
      );
      callback && callback();
    } catch (err) {
      message.error("There was a server error, please try again later.", 5);
    }
  };
}
