import * as Yup from "yup";

export default async function validateField(
  formValues,
  validationSchema,
  input,
  value,
) {
  return Yup.reach(validationSchema, input, formValues).validate(value);
}
