// front end validator, extends yup with custom methods
import * as Yup from "yup";
import axios from "axios";

// Add methods to extend the basic Yup validation

// String

Yup.addMethod(Yup.string, "alpha", function() {
  return this.test(
    "alpha",
    "Please use only the characters a-z, A-Z and spaces",
    function(value) {
      const { createError } = this;
      return (
        /^[a-zA-Z ]+$/.test(value) ||
        createError("Only alpha characters and spaces are allowed")
      );
    },
  );
});

Yup.addMethod(Yup.string, "alphanum", function() {
  return this.test(
    "alphanum",
    "Please use only the characters a-z, A-Z, 0-9 and spaces",
    function(value) {
      const { path, createError } = this;
      return (
        /^[a-zA-Z0-9 ]+$/.test(value) ||
        createError(path, "Only alphanumeric characters and spaces are allowed")
      );
    },
  );
});

Yup.addMethod(Yup.string, "postcode", function() {
  return this.test(
    "validPostcode",
    "Please enter a valid UK postcode",
    function(value) {
      const { path, createError } = this;
      return (
        /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/.test(
          value,
        ) || createError(path, "Postcode is not a valid UK postcode")
      );
    },
  );
});

Yup.addMethod(Yup.string, "checkPostcodesApi", function() {
  return this.test(
    "postcodesApiValidPostcode",
    "Please enter a valid UK postcode",
    function(value) {
      const { path, createError } = this;
      return new Promise((resolve, reject) => {
        if (
          /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/.test(
            value,
          )
        ) {
          const postcode = value.replace(" ", "");
          axios
            .get(`https://api.postcodes.io/postcodes/${postcode}`)
            .then(() => {
              resolve(true);
            })
            .catch(err => {
              if (err.response && err.response.status !== 404) {
                reject(
                  createError(
                    path,
                    "There was an error fetching postcode validation data. Please try again later",
                  ),
                );
              }
            });
        }
        reject(createError(path, "Postcode is not valid"));
      });
    },
  );
});

export default Yup;
